<template>
  <div class="virtual-game-show-image-list">
    <slot name="back-button" />
    <v-layout column flex>
      <v-layout class="virtual-game-show-image-list__header">
        <v-flex shrink>
          <v-btn
            @click="$emit('closeMenu')"
            class="virtual-game-show-image-list__header__close-btn"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
        <v-flex grow align-end justify-left ml-2 d-flex>
          <h2>Images</h2>
        </v-flex>
        <v-flex d-flex shrink align-center mr-2>
          <v-btn icon @click="$emit('showSubMenu', {})">
            <v-icon>add_circle</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-flex mt-3 ml-3 mr-3>
        <WeveTextField
          class="mb-3"
          v-model="searchImage"
          ref="searchField"
          hide-label
          label="Search Images"
          placeholder="Search Images"
          @keydown.enter="onSubmit"
        />
      </v-flex>
      <v-layout d-flex class="virtual-game-show-image-list__list" column>
        <v-layout
          row
          class="virtual-game-show-image-list__list__item"
          :class="{
            'virtual-game-show-image-list__list__item--active': image.active
          }"
          v-for="(image, index) in imagesComputed"
          :key="index"
          @click="setThearOverideImage(image)"
        >
          <v-flex shrink class="virtual-game-show-image-list-item__icon">
            <div
              class="virtual-game-show-image-list-item__icon-img"
              :style="`background: url('${image.url}') center / cover no-repeat;`"
            ></div>
            <div
              class="absolute"
              style="filter: brightness(1.9); color: #ffffff"
            >
              {{ image.keyPress }}
            </div>
          </v-flex>
          <v-flex grow ml-2 class="virtual-game-show-image-list-item__caption">
            <div class="virtual-game-show-image-list-item__caption__main-title">
              {{ image.title }}
            </div>
            <div class="virtual-game-show-image-list-item__caption__sub-title">
              {{ image.name }}
            </div>
          </v-flex>
          <v-flex shrink>
            <template v-if="image.active">
              <v-btn
                icon
                @click.stop="unsetThearOverideImage"
                class="close-icon"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </template>
            <template v-else>
              <div
                class="virtual-game-show-image-list__list__item__setting"
                @click.stop="$emit('showSubMenu', { data: image })"
              >
                <SvgIcon name="gear-3" width="20" height="20" />
                <SvgIcon name="chevron-right-regular" width="20" height="20" />
              </div>
            </template>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

import { WeveButton, WeveTextField } from "@weve/ui"

export default {
  name: "VirtualGameShowImageDrawer",
  components: {
    WeveButton,
    WeveTextField
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      searchImage: ""
    }
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission",
      game: "game"
    }),
    ...mapGetters(["gameID", "orgID", "actualGame"]),
    imagesComputed() {
      const images = this.images.map(image => ({
        ...image,
        active: this.actualGame.overrideImageUrl?.id === image?.id
      }))

      if (this.searchImage) {
        return images.filter(image => {
          return (
            String(image.title)
              .toLowerCase()
              .indexOf(this.searchImage.toLowerCase()) > -1
          )
        })
      }

      return images
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    async setThearOverideImage(image) {
      await this.updateGameAny({
        theKey: this.gameID,
        overrideImageUrl: {
          url: image.url,
          id: image.id
        }
      })
    },
    unsetThearOverideImage() {
      this.updateGameAny({
        theKey: this.gameID,
        overrideImageUrl: null
      })
    }
  }
}
</script>
<style lang="scss">
.virtual-game-show-image-list {
  background-color: #292932;
  height: 100%;
  color: white;
  &__header {
    &__close-btn {
      background: #292932 !important;
      box-shadow: 0px 8px 30px rgba(0, 0, 22, 0.25);
      border-radius: 0px 0px 20px 0px;
      width: 72px;
      height: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #3c3c3c;

      &.v-btn {
        margin: 0px;
      }
    }
  }
  &__list {
    &__item {
      $item: &;
      display: flex;
      align-items: center;
      height: 50px;
      margin: {
        top: 8px;
        right: 16px;
        bottom: 16px;
        left: 12px;
      }

      font-family: "Sofia Pro";
      .virtual-game-show-image-list-item__icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }
      .virtual-game-show-image-list-item__icon-img {
        opacity: 0.6;
        height: 100%;
        width: 100%;
        border-radius: 4px;
      }
      .virtual-game-show-image-list-item__caption {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        &__main-title {
          height: 15px;
          margin-bottom: 3px;
        }
        &__sub-title {
          font-weight: 700;
          height: 16px;
          font-size: 10px;
          line-height: 16px;
          color: #586475;
        }
      }

      &__setting {
        display: none;
      }

      &:hover {
        cursor: pointer;

        #{$item}__setting {
          display: flex;
          align-items: center;
        }
      }

      &:active {
        background: #393b42;
      }

      .close-icon {
        display: none;
      }

      &--active {
        padding-left: 8px;
        margin-left: 5px;
        background: #393b42;
        cursor: pointer;

        .close-icon {
          display: unset;
        }
      }
    }
  }
  &__tab {
    text-transform: none;
  }
}
</style>
