var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CustomDrawer", {
    staticStyle: {
      "background-color": "#1e2025",
      position: "absolute",
      top: "0",
    },
    attrs: {
      "show-sub-menu": _vm.showSubMenu,
      offsetY: _vm.offsetY,
      "menu-background": "#292932",
      "sub-menu-background": "#292932",
      "is-loading": _vm.isLoading,
      isTemporary: !_vm.forceOpen,
    },
    on: { updateShowSubMenu: _vm.updateShowSubMenu },
    scopedSlots: _vm._u([
      {
        key: "menu-content",
        fn: function () {
          return [
            _c("ImageList", {
              attrs: { images: _vm.images },
              on: {
                showSubMenu: _vm.showPanel,
                closeMenu: _vm.close,
                loading: _vm.setLoading,
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "submenu-content",
        fn: function (ref) {
          var back = ref.back
          return [
            _c("UploadNewImage", {
              key: _vm.imageData && _vm.imageData.id,
              attrs: { image: _vm.imageData },
              on: {
                back: back,
                delete: _vm.deleteImage,
                edit: _vm.edit,
                forceDrawer: _vm.forceDrawer,
              },
            }),
          ]
        },
      },
    ]),
    model: {
      value: _vm.drawerState,
      callback: function ($$v) {
        _vm.drawerState = $$v
      },
      expression: "drawerState",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }