
























import Vue from "vue"

export default Vue.extend({
  name: "WeveFileArea",
  props: {
    subtitle: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      draggedIn: false
    }
  },
  methods: {
    onFileInput({ target }: InputEvent) {
      if (target === null) return
      const { files } = target as HTMLInputElement
      if (files === null) return
      this.addFiles(files)
    },
    onFileDrop({ dataTransfer }: DragEvent) {
      if (dataTransfer === null) return null
      this.draggedIn = false
      const { files } = dataTransfer
      this.addFiles(files)
    },
    addFiles(files: FileList) {
      this.$emit("input", files[0])
    }
  }
})
