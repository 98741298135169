<template>
  <v-layout column class="virtual-game-show-upload-new-image">
    <v-flex mb-3 shrink class="virtual-game-show-upload-new-image__back-btn">
      <v-btn @click="$emit('back')" class="pl-0" flat>
        <h2>
          <SvgIcon name="chevron-left-regular" width="20" height="20" />
          {{ image ? "Edit Image" : "Upload New Image" }}
        </h2>
      </v-btn>
    </v-flex>
    <v-flex shrink>
      <div class="relative mb-3">
        <weve-text-field-with-validation
          label="Title"
          maxlength="25"
          required
          message="Required"
          characters-counter
          v-model="title"
        />
      </div>
    </v-flex>

    <v-flex mt-4 shrink style="position: relative">
      <div
        v-if="imgSrc"
        class="virtual-game-show-upload-new-image__image-preview-wrap"
      >
        <div
          class="virtual-game-show-upload-new-image__image-preview"
          :style="`background: url(${imgSrc}); background-size: 100% 100%;`"
        >
          <v-progress-circular
            indeterminate
            class="spinner"
            v-if="processing"
          />
          <v-layout
            column
            space-between
            pt-3
            pb-3
            fill-height
            absolute
            style="right: 0"
          >
            <v-flex align-start>
              <v-btn round icon @click="localImage = localImageData.url = ''">
                <SvgIcon name="trash-regular" width="20" height="20" />
              </v-btn>
            </v-flex>
            <v-flex d-flex align-end>
              <v-btn round icon @click="zoomImage">
                <SvgIcon name="zoom-in" size="small" width="20" height="20" />
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <FileArea @input="onFileInput" v-else />
    </v-flex>

    <v-layout mt-3 shrink class="virtual-game-show-upload-new-image__hotkey">
      <v-flex> HotKey </v-flex>
      <v-flex shrink>
        <div style="width: 50px; text-align: center">
          <weve-text-field-with-validation
            label="Type"
            hide-label
            :limit="1"
            maxlength="1"
            placeholder="Type"
            v-model="keyPress"
          />
        </div>
      </v-flex>
    </v-layout>

    <v-flex shrink mt-4 v-if="localImageData.id">
      <v-btn
        round
        block
        class="white--text font-weight-bold"
        color="#FF4060"
        @click="deleteImage"
      >
        Delete
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation"
import FileArea from "@/components/ui/FileArea/FileArea.vue"
import { uploadMedia } from "@/services/storage.service"
import uniqid from "uniqid"
import { debounce, cloneDeep } from "lodash"

import { db } from "@/firebase"

const isAcceptedFileFormat = type => {
  return type.includes("image")
}

export default {
  name: "UploadNewImage",
  components: {
    WeveTextFieldWithValidation,
    FileArea
  },
  data() {
    return {
      localImageData: {
        title: null,
        name: null,
        url: null,
        keyPress: null,
        id: null
      },
      processing: false,
      localImage: null
    }
  },
  props: {
    image: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    image: {
      handler(value) {
        if (!value) return
        this.localImageData = cloneDeep(value)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["orgID", "actualGameID", "actualGame"]),
    ...mapGetters("auth", ["token", "user"]),
    title: {
      get() {
        return this.localImageData?.title ?? null
      },
      set: debounce(async function (value) {
        this.localImageData.title = value
        await this.createOrUpdateImage({ title: value })
      }, 500)
    },
    keyPress: {
      get() {
        return this.localImageData?.keyPress ?? null
      },
      set: debounce(async function (value) {
        this.localImageData.keyPress = value
        await this.createOrUpdateImage({ keyPress: value })
      }, 500)
    },
    imgSrc() {
      return this.localImage ?? this.localImageData?.url ?? null
    }
  },
  methods: {
    async onFileInput(file) {
      if (!file) return

      const type = file.type
      const name = file.name

      if (!isAcceptedFileFormat(type)) {
        return alert("Unsupported File Format")
      }

      this.processing = true

      this.localImage = URL.createObjectURL(file)

      const imageUrl = await uploadMedia({
        fileName: `/virtual-game-show/${uniqid()}-${name}`,
        blob: file,
        token: this.token
      })

      await this.createOrUpdateImage({
        url: imageUrl,
        name
      })

      this.processing = false
    },
    async deleteImage() {
      this.$emit("delete", this.localImageData)

      this.$emit("back")
    },
    async zoomImage() {
      this.$emit("forceDrawer", true)
      await this.$info(
        `
        <h2 class="text-center">${this.localImageData.title}</h2>
        <div style="height: 100%; width: 100%; display: flex; align-items: end; justify-content: center;">
          <img src="${this.localImageData.url}" style="height: 70vh;" />
        </div>
      `,
        undefined,
        true
      )
      this.$emit("forceDrawer", false)
    },
    async createOrUpdateImage(image) {
      const localKey = this.localImageData?.id
      const key = image?.id ?? localKey ?? db.ref().push().key

      this.localImageData.id = key

      if (!(image?.created_at || this.localImageData?.created_at)) {
        image.created_at = Date.now()
      }

      await db
        .auxiliary()
        .ref(
          `org/${this.orgID}/virtual-game-show-images/${this.user.id}/${key}`
        )
        .update(image)

      if (!localKey) {
        this.$emit("edit", key)
      }
    }
  }
}
</script>

<style lang="scss">
.virtual-game-show-upload-new-image {
  background: #292932;
  color: white;
  padding: 14px;
  height: 100%;

  &__back-btn {
    .v-btn {
      margin: 0px !important;
      text-transform: unset;
    }
  }

  &__hotkey {
    .weve-text-input__field {
      padding: 0 5px !important;
      text-align: center;
      color: #f8f9fb;
    }
  }

  &__image-preview-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center;
  }

  &__image-preview {
    height: 22vh;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;

    img {
      width: 100%;
    }
  }
}
</style>
