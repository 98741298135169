<template>
  <CustomDrawer
    style="background-color: #1e2025; position: absolute; top: 0"
    v-model="drawerState"
    :show-sub-menu="showSubMenu"
    :offsetY="offsetY"
    menu-background="#292932"
    sub-menu-background="#292932"
    @updateShowSubMenu="updateShowSubMenu"
    :is-loading="isLoading"
    :isTemporary="!forceOpen"
  >
    <template #menu-content>
      <ImageList
        @showSubMenu="showPanel"
        :images="images"
        @closeMenu="close"
        @loading="setLoading"
      />
    </template>
    <template #submenu-content="{ back }">
      <UploadNewImage
        @back="back"
        :image="imageData"
        :key="imageData && imageData.id"
        @delete="deleteImage"
        @edit="edit"
        @forceDrawer="forceDrawer"
      />
    </template>
  </CustomDrawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex"

import state, { close } from "./index"
import Mode from "@shared/enums/Mode"
import CustomDrawer from "@/components/CustomDrawer.vue"
import ImageList from "./ImageList.vue"
import UploadNewImage from "./UploadNewImage.vue"
import { KEY_RETURN, KEY_SPACE } from "keycode-js"
import { db, Event as FirebaseEvent } from "@/firebase"

const serialize = string => String(string).toLowerCase().trim()

export default {
  name: "ImagesDrawer",
  components: {
    CustomDrawer,
    ImageList,
    UploadNewImage
  },
  data() {
    return {
      showSubMenu: false,
      isLoading: false,
      state,
      images: [],
      currentImageEditId: null,
      forceOpen: false
    }
  },
  created() {
    window.addEventListener("keyup", this.onKeyUp)
  },
  beforeDestroy() {
    this.imagesRef?.off(FirebaseEvent.VALUE, this.onImagesUpdate)
    window.removeEventListener("keyup", this.onKeyUp)
  },
  watch: {
    imagesRef: {
      handler(newValue, oldValue) {
        oldValue?.off(FirebaseEvent.VALUE, this.onImagesUpdate)
        newValue?.on(FirebaseEvent.VALUE, this.onImagesUpdate)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["orgID", "actualGameID", "actualGame", "gameStatus"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["getCurrentMode", "getCurrentMission"]),
    ...mapGetters({
      isHost: "auth/isHost"
    }),
    offsetY() {
      if (this.gameStatus.currentMode === Mode.Meeting) {
        return 0
      }

      return 40
    },
    imagesRef() {
      return this.actualGameID
        ? db
            .auxiliary()
            .ref(`org/${this.orgID}/virtual-game-show-images/${this.user.id}`)
        : null
    },
    imageData() {
      if (!this.currentImageEditId) return null
      return (
        this.images.find(image => image.id === this.currentImageEditId) || null
      )
    },
    isDrawerActive() {
      return Boolean(this.state.value || this.showSubMenu)
    },
    isTheaterVoting() {
      return (
        this.getCurrentMode === Mode.Voting &&
        this.getCurrentMission?.theaterVoting
      )
    },
    drawerState: {
      get() {
        return this.state.value
      },
      set(value) {
        this.state.value = value
      }
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    close,
    updateShowSubMenu(val) {
      this.showSubMenu = val
    },
    showPanel(obj) {
      const { data } = obj
      this.currentImageEditId = data?.id || null
      this.showSubMenu = true
    },
    setLoading(val) {
      this.isLoading = val
    },
    onImagesUpdate(snapshot) {
      const data = snapshot.val() || {}
      this.images = Object.entries(data).map(([id, image]) => ({
        ...image,
        id
      }))
    },
    async deleteImage(image) {
      if (image && image.id) {
        await db
          .auxiliary()
          .ref(
            `org/${this.orgID}/virtual-game-show-images/${this.user.id}/${image.id}`
          )
          .set(null)

        this.currentImageEditId = null
        this.showSubMenu = false
      }
    },
    edit(key) {
      this.currentImageEditId = key
    },
    onKeyUp({ keyCode, target, shiftKey }) {
      if (!this.isTheaterVoting || !shiftKey) {
        return
      }

      if (
        (["text", "textarea", "search", "number"].includes(target?.type) &&
          ![null, undefined, ""].includes(target?.value)) ||
        keyCode === KEY_SPACE ||
        keyCode === KEY_RETURN
      ) {
        return
      }

      this.onSetTheaterOverideImage(keyCode)
    },
    async onSetTheaterOverideImage(keyCode) {
      const keypress = String.fromCharCode(keyCode)
      const image = this.images.find(
        image => serialize(image.keyPress) == serialize(keypress)
      )

      if (image?.url && image?.id) {
        const updatePayload =
          image.url !== this.actualGame?.overrideImageUrl?.url
            ? {
                url: image.url,
                id: image.id
              }
            : null

        this.updateGameAny({
          theKey: this.actualGameID,
          overrideImageUrl: updatePayload
        })
      }
    },
    forceDrawer(value) {
      this.forceOpen = value || false
    }
  }
}
</script>
