var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "virtual-game-show-upload-new-image",
      attrs: { column: "" },
    },
    [
      _c(
        "v-flex",
        {
          staticClass: "virtual-game-show-upload-new-image__back-btn",
          attrs: { "mb-3": "", shrink: "" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "pl-0",
              attrs: { flat: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("back")
                },
              },
            },
            [
              _c(
                "h2",
                [
                  _c("SvgIcon", {
                    attrs: {
                      name: "chevron-left-regular",
                      width: "20",
                      height: "20",
                    },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.image ? "Edit Image" : "Upload New Image") +
                      " "
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("v-flex", { attrs: { shrink: "" } }, [
        _c(
          "div",
          { staticClass: "relative mb-3" },
          [
            _c("weve-text-field-with-validation", {
              attrs: {
                label: "Title",
                maxlength: "25",
                required: "",
                message: "Required",
                "characters-counter": "",
              },
              model: {
                value: _vm.title,
                callback: function ($$v) {
                  _vm.title = $$v
                },
                expression: "title",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "v-flex",
        {
          staticStyle: { position: "relative" },
          attrs: { "mt-4": "", shrink: "" },
        },
        [
          _vm.imgSrc
            ? _c(
                "div",
                {
                  staticClass:
                    "virtual-game-show-upload-new-image__image-preview-wrap",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "virtual-game-show-upload-new-image__image-preview",
                      style:
                        "background: url(" +
                        _vm.imgSrc +
                        "); background-size: 100% 100%;",
                    },
                    [
                      _vm.processing
                        ? _c("v-progress-circular", {
                            staticClass: "spinner",
                            attrs: { indeterminate: "" },
                          })
                        : _vm._e(),
                      _c(
                        "v-layout",
                        {
                          staticStyle: { right: "0" },
                          attrs: {
                            column: "",
                            "space-between": "",
                            "pt-3": "",
                            "pb-3": "",
                            "fill-height": "",
                            absolute: "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { "align-start": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { round: "", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.localImage = _vm.localImageData.url =
                                        ""
                                    },
                                  },
                                },
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: "trash-regular",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { "d-flex": "", "align-end": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { round: "", icon: "" },
                                  on: { click: _vm.zoomImage },
                                },
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: "zoom-in",
                                      size: "small",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _c("FileArea", { on: { input: _vm.onFileInput } }),
        ],
        1
      ),
      _c(
        "v-layout",
        {
          staticClass: "virtual-game-show-upload-new-image__hotkey",
          attrs: { "mt-3": "", shrink: "" },
        },
        [
          _c("v-flex", [_vm._v(" HotKey ")]),
          _c("v-flex", { attrs: { shrink: "" } }, [
            _c(
              "div",
              { staticStyle: { width: "50px", "text-align": "center" } },
              [
                _c("weve-text-field-with-validation", {
                  attrs: {
                    label: "Type",
                    "hide-label": "",
                    limit: 1,
                    maxlength: "1",
                    placeholder: "Type",
                  },
                  model: {
                    value: _vm.keyPress,
                    callback: function ($$v) {
                      _vm.keyPress = $$v
                    },
                    expression: "keyPress",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.localImageData.id
        ? _c(
            "v-flex",
            { attrs: { shrink: "", "mt-4": "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text font-weight-bold",
                  attrs: { round: "", block: "", color: "#FF4060" },
                  on: { click: _vm.deleteImage },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }