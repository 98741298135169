var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "weve-file-area",
      class: { "weve-file-area--dragged-in": _vm.draggedIn },
      on: {
        dragover: function ($event) {
          $event.preventDefault()
        },
        drop: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "weve-file-area__label",
          on: {
            dragleave: function ($event) {
              _vm.draggedIn = false
            },
            dragover: function ($event) {
              _vm.draggedIn = true
            },
            drop: _vm.onFileDrop,
          },
        },
        [
          _c("SvgIcon", {
            staticClass: "weve-file-area__icon",
            attrs: { name: "cloud-upload-regular" },
          }),
          _c("input", {
            staticClass: "weve-file-area__input",
            attrs: { type: "file" },
            on: { change: _vm.onFileInput },
          }),
          _vm._m(0),
          _vm.subtitle
            ? _c("p", { staticClass: "weve-file-area__subtitle" }, [
                _vm._v(_vm._s(_vm.subtitle)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "weve-file-area__title" }, [
      _vm._v(" Drop your file here or "),
      _c("span", { staticClass: "weve-file-area__title-link" }, [
        _vm._v("browse"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }