var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "virtual-game-show-image-list" },
    [
      _vm._t("back-button"),
      _c(
        "v-layout",
        { attrs: { column: "", flex: "" } },
        [
          _c(
            "v-layout",
            { staticClass: "virtual-game-show-image-list__header" },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "virtual-game-show-image-list__header__close-btn",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeMenu")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    grow: "",
                    "align-end": "",
                    "justify-left": "",
                    "ml-2": "",
                    "d-flex": "",
                  },
                },
                [_c("h2", [_vm._v("Images")])]
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    "d-flex": "",
                    shrink: "",
                    "align-center": "",
                    "mr-2": "",
                  },
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("showSubMenu", {})
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("add_circle")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { "mt-3": "", "ml-3": "", "mr-3": "" } },
            [
              _c("WeveTextField", {
                ref: "searchField",
                staticClass: "mb-3",
                attrs: {
                  "hide-label": "",
                  label: "Search Images",
                  placeholder: "Search Images",
                },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onSubmit.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.searchImage,
                  callback: function ($$v) {
                    _vm.searchImage = $$v
                  },
                  expression: "searchImage",
                },
              }),
            ],
            1
          ),
          _c(
            "v-layout",
            {
              staticClass: "virtual-game-show-image-list__list",
              attrs: { "d-flex": "", column: "" },
            },
            _vm._l(_vm.imagesComputed, function (image, index) {
              return _c(
                "v-layout",
                {
                  key: index,
                  staticClass: "virtual-game-show-image-list__list__item",
                  class: {
                    "virtual-game-show-image-list__list__item--active":
                      image.active,
                  },
                  attrs: { row: "" },
                  on: {
                    click: function ($event) {
                      return _vm.setThearOverideImage(image)
                    },
                  },
                },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "virtual-game-show-image-list-item__icon",
                      attrs: { shrink: "" },
                    },
                    [
                      _c("div", {
                        staticClass:
                          "virtual-game-show-image-list-item__icon-img",
                        style:
                          "background: url('" +
                          image.url +
                          "') center / cover no-repeat;",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "absolute",
                          staticStyle: {
                            filter: "brightness(1.9)",
                            color: "#ffffff",
                          },
                        },
                        [_vm._v(" " + _vm._s(image.keyPress) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass: "virtual-game-show-image-list-item__caption",
                      attrs: { grow: "", "ml-2": "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "virtual-game-show-image-list-item__caption__main-title",
                        },
                        [_vm._v(" " + _vm._s(image.title) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "virtual-game-show-image-list-item__caption__sub-title",
                        },
                        [_vm._v(" " + _vm._s(image.name) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      image.active
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "close-icon",
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.unsetThearOverideImage.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "virtual-game-show-image-list__list__item__setting",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit("showSubMenu", {
                                      data: image,
                                    })
                                  },
                                },
                              },
                              [
                                _c("SvgIcon", {
                                  attrs: {
                                    name: "gear-3",
                                    width: "20",
                                    height: "20",
                                  },
                                }),
                                _c("SvgIcon", {
                                  attrs: {
                                    name: "chevron-right-regular",
                                    width: "20",
                                    height: "20",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }